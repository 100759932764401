const linksData = [
  {
    name: 'BlueSky',
    url: 'https://bsky.app/profile/arcticf0xx.com',
    icon: 'FaBluesky',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/taylorjacobsen/',
    icon: 'FaLinkedin',
  },
  {
    name: 'Threads',
    url: 'https://threads.net/@taylorjacobsen/',
    icon: 'FaThreads',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/taylorjacobsen',
    icon: 'FaTwitter',
  },
];

export default linksData;
