import React from "react";
import "./App.css";
import {
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLinkedin,
  FaMastodon,
  FaYoutube,
  FaCloud,
  FaBluesky,
  FaThreads,
} from "react-icons/fa6";
import linksData from "./linksData";
import profilePicture from "./profilePicture.png";

const IconMap = {
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLinkedin,
  FaMastodon,
  FaYoutube,
  FaCloud,
  FaBluesky,
  FaThreads,
};

const LinkIcon = React.memo(({ link }) => {
  const Icon = IconMap[link.icon];
  return (
    <a
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-link"
    >
      <Icon />
      <span>{link.name}</span>
    </a>
  );
});

const Link = React.memo(({ href, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-link"
    >
      {children}
    </a>
  );
});

const App = () => {
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="container">
      <div className="profile-container">
        <img
          src={profilePicture}
          alt="Profile"
          className="profile-picture"
          loading="lazy"
        />
        <h1 className="profile-name" style={{ color: '#008080' }}>Taylor Jacobsen</h1>
        <p className="profile-bio">
          Taylor is the AWS Heroes Program Manager who extends her support to
          the AWS Community Builders. She is passionate about creating the best
          possible experience for the customer. Outside of work, she is
          connected to gaming communities, and is currently playing Elder
          Scrolls Online (ESO).
          <br/><br/>Calla and Cirilla's mamma. Engaged to <Link href="https://bsky.app/profile/gunnargrosch.com">Gunnar Grosch</Link>. California + Sweden = Home.
        </p>
      </div>
      <div className="links-container">
        {linksData.map((link) => (
          <LinkIcon key={link.url} link={link} />
        ))}
      </div>
      <footer className="footer">
        &copy; {currentYear}{" "}
        <Link href="https://bsky.app/profile/arcticf0xx.com">
          Taylor Jacobsen
        </Link>
        . All rights reserved.
      </footer>
    </div>
  );
};

export default App;